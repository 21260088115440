import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../components/floating-button/floating-button';
import Layout from '../../../components/layout';
import React from 'react';

const WhoWeArePage = (props) => {
    const components = props.data.cms.whoWeArePageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default WhoWeArePage;

export const query = graphql`
query ($locale: String!){
  cms {
    whoWeArePageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentSplitBackgroundSplitBackgroun {
          __typename
          topPusherHeight
          topPusherHeightMobile
          bottomItems
          leftSide {
            content
            title
          }
          rightSide {
            description
            imageData
            title
            images {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsTheyTrustUs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          theyTrustUsScrollColor
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          mobileImages {
            url
          }
          linesVisible
          images {
            url
          }
        }
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          title
          textBoxCustomClasses
          headerSubtitle: subtitle
          mobileContentOffset
          contentOffset
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          backgroundImageHeight
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          tabsDarkBackground
          tabs {
            type
            title
            subtitle
            scrollColor
            lottieData
            link
            labelImage {
              url
            }
            labelColour
            label
            isLabelImageVisibleOnDesktop
            imagesTitles
            imagesMetadata
            images {
              url
            }
            fullWidthImageItemsDirection
          }
          tabHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          middleImage {
            url
          }
        }
        ... on STRAPI_ComponentOurOfficesOurOffices {
          __typename
          topPusherHeight
          topPusherHeightMobile
          warsawData {
            officesData
            image {
              url
            }
            cityName
          }
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          londonData {
            cityName
            image {
              url
            }
            officesData
          }
        }
        ... on STRAPI_ComponentEmployeesListBoardList {
          __typename
          topPusherHeight
          topPusherHeightMobile
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          employee {
            employee {
              bio
              image {
                url
              }
              nameAndSurname
              title
            }
          }
        }
        ... on STRAPI_ComponentOurTeamListOurTeamList {
          __typename
          topPusherHeight
          topPusherHeightMobile
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          bioButtonLabel
          employees {
            employee {
              title
              nameAndSurname
              image {
                url
              }
              bio
            }
          }
          newTeammateButtonLabel
          newTeammateSubtitle
          newTeammateTitle
          scrollColor
        }
        ... on STRAPI_ComponentComponentsWhoWeAreTimeline {
          __typename
          sectionTitle {
            description
            style
            tailwindClasses
            title
            titleIcon {
              url
            }
            ytLink
          }
          topPusherHeight
          topPusherHeightMobile
          whoWeAreTimelineItem {
            date
            title
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
      }
    }
  }
}
`;
